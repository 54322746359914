<template>
  <div class="security">
    <div class="head-panel">
      <div class="left">
        <div class="img">
          <img src="../../../assets/images/ori/security.png" alt="" srcset="" />
        </div>
        <div class="desc">
          当前安全等级：
          <span>高</span>
        </div>
      </div>

      <div class="right">
        <div class="line-wrap">
          <img
            src="../../../assets/images/ori/safe-line.jpg"
            alt=""
            srcset=""
          />
          <div class="line">
            <span class="line-inner"></span>
          </div>
        </div>
        <div class="tip">
          安全等级过低会影响您的资金安全建议尽快提升安全等级
        </div>
      </div>
    </div>

    <div class="middle-panel">
      <i class="el-icon-umbrella"></i>
      安全中心
    </div>

    <div class="bottom-panel">
      <div class="content-row">
        <div>
          <i class="el-icon-mobile"></i>
          <span>绑定手机</span>
        </div>
        <div>
          当前绑定手机号：150****0000，绑定的手机号可以通过短信找回登录密码，资金交易密码
        </div>

        <div>
          <i class="el-icon-success"></i>
          <span>已设置</span>
        </div>

        <div>
          <el-link :underline="false">修改绑定</el-link>
        </div>
      </div>
      <div class="content-row">
        <div>
          <i class="el-icon-lock"></i>
          <span>登录密码</span>
        </div>
        <div>
          网站密码用于用户登录，请妥善保管密码，数科邦工作人员不会以任何理由向您索取密码
        </div>

        <div>
          <i class="el-icon-success"></i>
          <span>已设置</span>
        </div>

        <div>
          <el-link :underline="false">修改密码</el-link>
        </div>
      </div>
      <div class="content-row">
        <div>
          <i class="el-icon-s-finance"></i>
          <span>资金密码</span>
        </div>
        <div>
          资金密码非常重要，是在资金账户变动时需要输入的密码，要及时设置
        </div>

        <div>
          <i class="el-icon-success"></i>
          <span>已设置</span>
        </div>

        <div>
          <el-link :underline="false">修改密码</el-link>
          <span>|</span>
          <el-link :underline="false">密码找回</el-link>
        </div>
      </div>
      <div class="content-row">
        <div>
          <i class="el-icon-postcard"></i>
          <span>实名认证</span>
        </div>
        <div>实名认证可以提高账户的安全性，享受更多数科邦平台的服务和保障</div>

        <div>
          <i class="el-icon-success"></i>
          <span>已认证</span>
        </div>

        <div>
          <el-link :underline="false">查看详情</el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "security",
};
</script>

<style lang="scss" scoped>
.security {
  .head-panel {
    background: #fff;
    margin-bottom: 12px;
    height: 186px;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;

    .left {
      flex: 0 0 auto;
      width: 265px;
      text-align: center;
      font-size: 18px;
      span {
        font-weight: bold;
        color: #81efa0;
      }
    }

    .right {
      margin-top: 30px;
      position: relative;

      .line-wrap {
        .line {
          position: absolute;
          height: 8px;
          width: 100%;
          top: 27px;
          left: 18px;
          padding-right: 23px;
          line-height: 8px;

          .line-inner {
            width: 100%;
            background-color: #81efa0;
            border-radius: 8px;
            display: inline-block;
            height: 8px;
          }
        }
      }

      .tip {
        color: #999999;
        margin-top: 25px;
        font-size: 12px;
      }
    }
  }

  .middle-panel {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;

    i {
      font-size: 18px;
      margin-right: 4px;
    }
  }

  .bottom-panel {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;

    .content-row + .content-row {
      border-top: 1px solid #f5f5f5;
    }

    .content-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 110px;

      cursor: pointer;

      &:hover,
      &:active {
        background: #f7f7f7;
      }

      & > div:nth-child(1) {
        flex: 0 0 auto;
        width: 160px;
        text-align: center;
        color: #666;

        i {
          font-size: 18px;
          margin-right: 4px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }

      & > div:nth-child(2) {
        flex: 1 1 auto;
        padding: 0 10px;
        color: #999;
        font-size: 12px;
      }

      & > div:nth-child(3) {
        flex: 0 0 auto;
        width: 130px;
        text-align: center;
        font-size: 12px;
        color: #37d778;
        i {
          font-size: 20px;
          margin-right: 2px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }

      & > div:nth-child(4) {
        flex: 0 0 auto;
        width: 195px;
        text-align: right;
        padding-right: 30px;
        font-size: 12px;

        span {
          margin: 0 4px;
        }
      }
    }
  }
}
</style>